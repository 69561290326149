const en_US = {
  platform: 'Intelligent AI system',
  Log_In: 'Log in',
  Log_up: 'Sign up',
  Forget_Password: 'Forgot Password?',
  // ForgotPassword: 'Send Reset Link',
  ReturnLogin: 'Return to Login',
  Sign_up: 'Sign up',
  Home: 'Home',
  CreateInterview: 'Create Interview',
  ExecuteInterview: 'Execute Interview',
  TestInterview: 'Test Interview',
  Report: 'Report',
  UserCenter: 'User Center',
  Search: ' Search',
  Welcome: 'Welcome,',
  Confirm: 'Confirm',
}
export default en_US
